import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Layout from '../components/Layout'

const styles = theme => ({
    wholeContent: {
        '& div': {
            paddingTop: theme.spacing.unit*2
        },
        padding: theme.spacing.unit*4
    },
    noPage: {

    }
})

const NotFoundPage = ({ classes }) => (
    <Layout isPage={true}>
        <div className={classes.wholeContent}>
            <Typography variant='display2' gutterBottom={true} align='center' className={classes.noPage}>
                NOTHING HERE 😨
            </Typography>
            <Typography align='center' component='div'>
                Where are you going? Is this even legal? What were you thinking? Why is this missing? Where will you go now?
            </Typography>
            <Typography align='center' component='div'>
                So many questions, not enough pages.
            </Typography>
            <Typography align='center' component='div'>
                Gosh, you give me a headache...
            </Typography>
        </div>
    </Layout>
)

export default withStyles(styles)(NotFoundPage)
